import {mapGetters, mapActions, mapMutations} from 'vuex';
import AccessRoleTypes from "@/services/AccessRoleTypes";
import UserGroupHelper from "@/services/UserGroupHelper";
import OrganizationLevelTypes from "@/services/OrganizationLevelTypes";
import ElectionYearService from "@/services/ElectionYearService";

export default {
    data(){
        return{
            OrganizationLevelTypes,
            AccessRoleTypes
        }
    },
    props: {
        accessLevel: Number,
        mid: String,
    },
    components: {},
    watch: {
        activeUserRole(value) {
            if (this.accessLevel && value.level !== this.accessLevel) {
                this.navigatePortalHome();
            }
        }
    },
    computed: {
        midFromUrl() {
            if (!this.$route.params.mid) {
                if (this.$store.getters.activeUserRole)
                    return this.$store.getters.activeUserRole.groupValue;
                else
                    return "";
            }

            return this.$route.params.mid.toString();
        },
        licenseBaseUrl() {
            return __LICENSE_BASEURL__;
        },
        ...mapGetters([
            "userRoles",
            "getUserInfo",
            "activeUserRole",
            "getElectionYear",
            "hasElectionAdminGlobalRole"
        ])
    },
    methods: {
        ...mapActions([
            "loadElectionDocument"]),
        loadDocument() {
            this.loadElectionDocument({year : this.getElectionYear,mid : this.midFromUrl,route : this.$route});
        },
        hasRole(accessRole: AccessRoleTypes) {
            if (this.activeUserRole == null || this.activeUserRole === {})
                return false;

            return UserGroupHelper.ContainsRole(this.activeUserRole.accessRoles, accessRole);
        },
        navigatePortalHome() {

            var path = this.$route.path;
            let name = "MRHome";
            if(path.startsWith("/mr"))
                name = "MRHome";
            
            if(path.startsWith("/stift"))
                name = "StiftHome";

            if(path.startsWith("/provsti"))
                name = "ProvstiHome";

            if(path.startsWith("/ot"))
                name = "KirkegaardHome";
            
            if (this.$route.name === name)
                return;
            
            if (this.midFromUrl)
                this.$router.push({
                    name: name,
                    params: {mid: this.midFromUrl}
                });
            else
                this.$router.push({name: "MRHome", params: {}});

        },
        navigateBrugerMenuHome() {

            var path = this.$route.path;
            let name = "PortalBrugereRettighederMenu";
            if (path.startsWith("/mr"))
                name = "PortalBrugereRettighederMenu";

            if (path.startsWith("/ot"))
                name = "OtherBrugereRettighederMenu";

            if (this.$route.name === name)
                return;

            if (this.midFromUrl)
                this.$router.push({
                    name: name,
                    params: { mid: this.midFromUrl }
                });
            else
                this.$router.push({ name: "MRHome", params: {} });

        },
        checkAndSetGroupFromUrl() {
            if (this.midFromUrl) 
              ElectionYearService.LoadElectionYearAndDocument(this.midFromUrl,this.$route,this.loadDocument);
            
        },
        environmentIsProd() {
            return this.environment === "production" || true;
        },
        userHasAccounting() {
            return UserGroupHelper.ContainsRole(this.activeUserRole.accessRoles, AccessRoleTypes.Accounting);
        },
    }
}